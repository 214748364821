.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for better readability */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #B22222; /* Match with your theme */
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 25px; /* Increased margin for better spacing */
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px; /* Added margin to separate label from input */
  }
  
  input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%; /* Ensure inputs take full width */
  }
  
  textarea {
    resize: vertical;
  }
  
  .submit-button {
    background-color: #B22222; /* Match with your theme */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #A00000; /* Darker shade for hover effect */
  }
  
  .contact-info {
    margin-top: 20px;
    text-align: left;
  }
  