/* Services.css */
.services-container {
    padding: 20px;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #B22222; /* Maroonish red color */
  }
  
  .service-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    cursor: pointer; /* Indicate that the title is clickable */
    transition: background-color 0.3s; /* Smooth background transition */
  }
  
  .service-item:hover {
    background-color: #f1f1f1; /* Light gray on hover */
  }
  
  .service-title {
    font-size: 1.5em;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .service-description {
    margin-top: 10px;
    font-size: 1em;
    color: #333; /* Darker text for description */
  }
  