@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.home-container {
  padding: 20px;
}

h2 {
  font-size: 2rem;
  color: #B22222; /* Maroonish red color */
  margin-top: 20px;
}

.mission-section,
.expertise-section {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px; /* Space between sections */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.mission-image-container,
.expertise-image-container {
  flex: 1;
  margin-right: 20px; /* Space between image and text */
}

.mission-image{
    max-width: 300px; /* Limit maximum image width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Slightly rounded corners */
  }

  .expertise-image {
    max-width: 300px; /* Limit maximum image width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Slightly rounded corners */
    margin-top: 120px; 
  }

.mission-text,
.expertise-text {
  flex: 2;
}

ul,
ol {
  padding-left: 20px; /* Indentation for lists */
}

li {
  margin-bottom: 15px; /* Space between list items */
  color: #555; /* Dark gray for text */
  line-height: 1.6; /* Better readability */
}

strong {
  color: #B22222; /* Emphasize with maroonish red */
}

.logo-container {
    text-align: center; /* Center the logo */
    margin-bottom: 20px; /* Space below the logo */
  }
  
  .logo {
    max-width: 150px; /* Adjust the logo size */
    height: auto; /* Maintain aspect ratio */
  }
