/* NavBar.css */
.navbar {
    background-color: #C03C3C;  /* Same maroonish background */
    padding: 10px 20px;
  }
  
  .nav-links {
    list-style: none; 
    display: flex; /* Aligns items in a row */
    justify-content: flex-start; /* Aligns items to the left */
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin: 0 15px; /* Space between the buttons */
  }
  
  .nav-links a {
    text-decoration: none; /* Removes underline from links */
    color: white; /* Text color */
    transition: border-bottom 0.3s ease; /* Smooth transition for underline */
  }
  
  /* Hover and active state styling */
  .nav-links a:hover, .nav-links a.active {
    border-bottom: 2px solid white; /* Underline effect */
  }